import styled from '@emotion/styled'

export const TeacherStyleContainer = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
`

export const TeacherStyleContents = styled.div`
  display: flex;
  width: 85%;
  flex-direction: column;
  align-items: center;
`

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;

  .add-class-button,
  .view-class-button {
    padding: 10px 35px;
    cursor: pointer;
    margin: 10px 0px;
    color: #ffffff;
    border-radius: 2px;
  }

  .add-class-button:hover,
  .view-class-button:hover {
    background-color: #fd7603;
  }

  .add-class-button {
    background-color: ${(props) => props.addButtonColor};
  }

  .view-class-button {
    background-color: ${(props) => props.viewButtonColor};
  }
`

export const AddClassContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`

export const AddClassForm = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
`

export const AddClassConfirm = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .class-submit-button {
    background-color: #fd7603;
    padding: 10px 35px;
    cursor: pointer;
    margin: 10px 0px;
    color: #ffffff;
    border-radius: 2px;
  }
`

export const ViewClassContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const ViewClassCardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`

export const ViewClassCardContainer = styled.div``
