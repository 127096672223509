//module imports
import React, { useState, useContext, useEffect } from 'react'
import { useMutation, useLazyQuery, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

// local imports
import Seo from 'components/General/SEO'
import Layout from 'components/Layout'
import {
  CREATE_CLASS,
  GET_CLASSES_AS_TEACHER,
  GET_CLASS_SESSION_ACCESS,
} from 'utils/graphql'
import LottieShape from 'components/General/LottieShape'
import LoadingAnimation from 'components/General/Loading/LoadingAnimation'
import UserContext from 'context/user/UserContext'
import SubscriptionContext from 'context/subscription/SubscriptionContext'
import { useAuth0 } from 'utils/react-auth0-spa'
import moment from 'moment'

// import style components
import {
  TeacherStyleContainer,
  TeacherStyleContents,
  ButtonsContainer,
  AddClassContainer,
  AddClassForm,
  AddClassConfirm,
  ViewClassContainer,
  ViewClassCardsContainer,
  ViewClassCardContainer,
} from 'components/styleComponents/TeacherPage/teachersStyles'

// asset imports

const Teachers = () => {
  const [teacherViewToggle, setTeacherViewToggle] = useState(true)
  // all the variables for a class to be added
  const [language, setLanguage] = useState('English')
  const [category, setCategory] = useState('General')
  const [level, setLevel] = useState('Level 1')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [day1, setDay1] = useState('')
  const [day1Time, setDay1Time] = useState('')
  const [day2, setDay2] = useState('')
  const [day2Time, setDay2Time] = useState('')
  const [teacherId, setTeacherId] = useState('')
  const [maxStudents, setMaxStudents] = useState(0)
  const [description, setDescription] = useState('')
  // end variables for a class to be added
  const [addButtonColor, setAddButtonColor] = useState('#fdbf03')
  const [viewButtonColor, setViewButtonColor] = useState('#fd7603')
  // dummy variable to force refresh
  // todays date for default values in the forms
  let today = new Date()
  const dd = String(today.getDate()).padStart(2, '0')
  const mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
  const yyyy = today.getFullYear()

  today = mm + '/' + dd + '/' + yyyy
  // maximum date for a class start date - 1 year from today.
  const maximumDate = mm + '/' + dd + '/' + (yyyy + 1)

  // global state/context
  const {
    loading: getClassesLoading,
    error: getClassesError,
    data: getClassesData,
  } = useQuery(GET_CLASSES_AS_TEACHER, {
    variables: {
      input: {
        status: ['OPEN', 'ACTIVE'],
      },
    },
  })
  const [
    createClass,
    { loading: createClassLoading, error: createClassError },
  ] = useMutation(CREATE_CLASS)

  const [getClassSessionAccess, { data: getClassAccessData }] = useLazyQuery(
    GET_CLASS_SESSION_ACCESS,
  )

  const { firestoreUserObject, userStateLoading } = useContext(UserContext)
  const { subscriptionStateLoading } = useContext(SubscriptionContext)
  const { isAuthenticated, auth0Loading } = useAuth0()

  // function to swap between the "add class" and "view classes" views.
  const toggleView = (action) => {
    setTeacherViewToggle(action.target.id === 'view')
    if (action.target.id === 'view') {
      setViewButtonColor('#fd7603')
      setAddButtonColor('#fdbf03')
    } else {
      setViewButtonColor('#fdbf03')
      setAddButtonColor('#fd7603')
    }
  }

  const accessClass = async (classId) => {
    try {
      getClassSessionAccess({
        variables: {
          input: {
            id: classId,
          },
        },
      })
    } catch (err) {
      console.log(err)
    }
  }

  // function to process form data and send it to firebase.
  const addClassToFirebase = async () => {
    // validate the form data has been provided
    if (
      language === '' ||
      category === '' ||
      level === '' ||
      startDate === '' ||
      endDate === '' ||
      day1 === '' ||
      day1Time === '' ||
      day2 === '' ||
      day2Time === '' ||
      teacherId === '' ||
      maxStudents === 0 ||
      description === ''
    ) {
      return toast.error('Please fill out ALL form fields before submitting.')
    }
    // validate the start and end dates make sense
    if (moment(endDate).unix() <= moment(startDate).unix())
      return toast.error('End date cannot be earlier than the start date.')

    try {
      await createClass({
        variables: {
          input: {
            language: language.toUpperCase(),
            category: category.toUpperCase(),
            level: level.replace(' ', '').toUpperCase(),
            startDate: startDate,
            endDate: endDate,
            days: [
              {
                dayOfWeek: day1.toUpperCase(),
                timeOfDay: day1Time,
              },
              {
                dayOfWeek: day2.toUpperCase(),
                timeOfDay: day2Time,
              },
            ],
            instructorId: teacherId,
            maxStudents: maxStudents,
            description: description,
          },
        },
      })

      if (createClassError) {
        console.error('Error Creating Class: ', createClassError)
        toast.error('Error creating your class. Contact Engineering.')
      } else {
        toast.success('Successfully created class.')
      }
    } catch (err) {
      console.error('Error creating class: ', err)
      toast.error(
        'There was an error creating your class. Contact Engineering.',
      )
    }
  }

  // redirect to the 3dmeet session when the teacher clicks on the "join class" button
  useEffect(() => {
    if (
      getClassAccessData?.getClassSessionAccess?.access?.redirectUrl &&
      typeof window !== 'undefined'
    ) {
      window.location.assign(
        getClassAccessData.getClassSessionAccess.access.redirectUrl,
      )
    }
  }, [getClassAccessData])

  return (
    <Layout>
      <Seo
        title="FluentWorlds Academy - Teachers Page"
        description="This is a page for FluentWorlds Academy teachers to create, view, and join classes for the Academy."
      />

      <TeacherStyleContainer>
        {userStateLoading || subscriptionStateLoading || auth0Loading ? (
          <p>Loading...</p>
        ) : !firestoreUserObject?.fwaProfile?.isTeacher || !isAuthenticated ? (
          <p>You must be logged in as a teacher to view this content.</p>
        ) : (
          <TeacherStyleContents>
            <ButtonsContainer
              addButtonColor={addButtonColor}
              viewButtonColor={viewButtonColor}
            >
              <div
                role="button"
                className="add-class-button"
                id="add"
                onClick={toggleView}
                onKeyDown={toggleView}
                tabIndex={0}
              >
                Add Class
              </div>
              <div
                role="button"
                className="view-class-button"
                id="view"
                onClick={toggleView}
                onKeyDown={toggleView}
                tabIndex={0}
              >
                View Classes
              </div>
            </ButtonsContainer>

            {!teacherViewToggle ? (
              <AddClassContainer>
                <AddClassForm>
                  <form>
                    <label htmlFor="language">
                      Class Language
                      <select
                        name="language"
                        id="language"
                        onBlur={(ev) => setLanguage(ev.target.value)}
                      >
                        <option value="English">English</option>
                      </select>
                    </label>

                    <br />
                    <label htmlFor="category">
                      Class Category:{' '}
                      <select
                        name="category"
                        id="category"
                        onBlur={(ev) => setCategory(ev.target.value)}
                      >
                        <option value="General">General</option>
                        <option value="Academic">Academic</option>
                        <option value="Business">Business</option>
                        {/* NOTE: the individual tutoring option will need to be handled differently. We can probably use Calendly for that. */}
                      </select>
                    </label>

                    <br />
                    <label htmlFor="level">
                      Class Level:{' '}
                      <select
                        name="level"
                        id="level"
                        onBlur={(ev) => setLevel(ev.target.value)}
                      >
                        <option value="Level 1">Level 1 (A1/A2)</option>
                        <option value="Level 2">Level 2 (B1)</option>
                        <option value="Level 3">Level 3 (B2+)</option>
                        {/* NOTE: the individual tutoring option will need to be handled differently. We can probably use Calendly for that. */}
                      </select>
                    </label>

                    <br />
                    <label htmlFor="startDate">
                      Start Date:
                      <br />
                      <input
                        type="date"
                        id="startDate"
                        name="startDate"
                        min={today}
                        max={maximumDate}
                        onChange={(ev) => setStartDate(ev.target.value)}
                      />
                    </label>

                    <br />
                    <label htmlFor="endDate">
                      End Date: <br />
                      <input
                        type="date"
                        id="endDate"
                        name="endDate"
                        min={today}
                        onChange={(ev) => setEndDate(ev.target.value)}
                      />
                    </label>

                    <br />
                    <label htmlFor="day1">
                      Day 1 - Day-Of-Week:
                      <br />
                      <select
                        name="day1"
                        id="day1"
                        onBlur={(ev) => setDay1(ev.target.value)}
                      >
                        <option value="Sunday">Sunday</option>
                        <option value="Monday">Monday</option>
                        <option value="Tuesday">Tuesday</option>
                        <option value="Wednesday">Wednesday</option>
                        <option value="Thursday">Thursday</option>
                        <option value="Friday">Friday</option>
                        <option value="Saturday">Saturday</option>
                      </select>
                    </label>

                    <br />
                    <label htmlFor="day1Time">
                      Day 1 - Time (US Mountain Time): <br />
                      <input
                        type="time"
                        id="day1Time"
                        name="day1Time"
                        onBlur={(ev) => setDay1Time(ev.target.value)}
                      />
                    </label>

                    <br />
                    <label htmlFor="day2">
                      Day 2 - Day-Of-Week: <br />
                      <select
                        name="day2"
                        id="day2"
                        onBlur={(ev) => setDay2(ev.target.value)}
                      >
                        <option value="Sunday">Sunday</option>
                        <option value="Monday">Monday</option>
                        <option value="Tuesday">Tuesday</option>
                        <option value="Wednesday">Wednesday</option>
                        <option value="Thursday">Thursday</option>
                        <option value="Friday">Friday</option>
                        <option value="Saturday">Saturday</option>
                      </select>
                    </label>

                    <br />
                    <label htmlFor="day2Time">
                      Day 2 - Time (US Mountain Time): <br />
                      <input
                        type="time"
                        id="day2Time"
                        name="day2Time"
                        onChange={(ev) => setDay2Time(ev.target.value)}
                      />
                    </label>

                    <br />
                    <label htmlFor="teacherId">
                      Teacher User ID:
                      <br />
                      <input
                        type="text"
                        id="teacherId"
                        name="teacherId"
                        onChange={(ev) => setTeacherId(ev.target.value)}
                      />
                    </label>

                    <br />
                    <label htmlFor="maxStudents">
                      Max Students:
                      <br />
                      <input
                        type="number"
                        id="maxStudents"
                        name="maxStudents"
                        min="1"
                        max="25"
                        onChange={(ev) =>
                          setMaxStudents(parseInt(ev.target.value))
                        }
                      />
                    </label>

                    <br />
                    <label htmlFor="description">
                      Class Description: <br />
                      <textarea
                        id="description"
                        name="description"
                        cols="30"
                        rows="5"
                        onChange={(ev) => setDescription(ev.target.value)}
                      />
                    </label>

                    <br />
                  </form>
                </AddClassForm>
                <AddClassConfirm>
                  <h3>Class Details:</h3>
                  <ul>
                    <li>Language: {language}</li>
                    <li>Category: {category}</li>
                    <li>Level: {level}</li>
                    <li>
                      Start Date:{' '}
                      {startDate && moment(startDate).format('MMMM Do YYYY')}
                    </li>
                    <li>
                      End Date:{' '}
                      {endDate && moment(endDate).format('MMMM Do YYYY')}
                    </li>
                    <li>
                      Days/Time: {day1} {day1Time} / {day2} {day2Time}{' '}
                    </li>
                    <li>Teacher: {teacherId}</li>
                    <li>Max Students: {maxStudents}</li>
                  </ul>
                  {createClassLoading ? (
                    <LottieShape animationData={LoadingAnimation} />
                  ) : (
                    <div
                      role="button"
                      className="class-submit-button"
                      onClick={addClassToFirebase}
                      onKeyDown={addClassToFirebase}
                      tabIndex={0}
                    >
                      Submit Class
                    </div>
                  )}
                </AddClassConfirm>
              </AddClassContainer>
            ) : (
              <ViewClassContainer>
                <h2>All Classes:</h2>
                {getClassesLoading ? (
                  <p>Retrieving classes...</p>
                ) : getClassesError ? (
                  <p>There was an error retrieving the classes.</p>
                ) : (
                  <ViewClassCardsContainer>
                    {getClassesData?.getClasses?.classes.map((oneClass, id) => {
                      return (
                        <ViewClassCardContainer key={id}>
                          <h2>
                            FLUENTWORLDS {oneClass.category} {oneClass.language}
                          </h2>
                          <p>Start Date: {oneClass.startDate}</p>
                          <p>End Date: {oneClass.endDate}</p>
                          Days:
                          <ul>
                            <li>
                              {oneClass.days[0].dayOfWeek}{' '}
                              {oneClass.days[0].timeOfDay}
                            </li>
                            <li>
                              {oneClass.days[1].dayOfWeek}{' '}
                              {oneClass.days[1].timeOfDay}
                            </li>
                          </ul>
                          <p>Description: {oneClass.description}</p>
                          <p>
                            Capacity: {oneClass.students.length}/
                            {oneClass.maxStudents}
                          </p>
                          <p>Students:</p>
                          <ul>
                            {oneClass.students.map((student, index) => {
                              return <li key={index}>{student.email}</li>
                            })}
                          </ul>
                          <button onClick={() => accessClass(oneClass.id)}>
                            Join Class
                          </button>
                        </ViewClassCardContainer>
                      )
                    })}
                  </ViewClassCardsContainer>
                )}
              </ViewClassContainer>
            )}
          </TeacherStyleContents>
        )}
      </TeacherStyleContainer>
    </Layout>
  )
}

export default Teachers
